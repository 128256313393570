<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Area"
    allow-clear
    :show-arrow="true"
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="() => (findText = null)"
    show-search
    v-bind="$attrs"
  >
    <a-select-option v-for="(d, index) in data" :key="index" :value="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
        <ASpin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, merge, keyBy, values } from 'lodash'

export default {
  props: {
    mode: {
      type: String,
      default: 'multiple',
    },
    value: {
      default: () => [],
      type: Array,
    },
    provinsi: {
      default: () => [],
      type: Array,
    },
    region: {
      default: () => [],
      type: Array,
    },
    joinOptions: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const data = ref([])
    const findText = ref(null)
    const provinsi = toRefs(props).provinsi
    const region = toRefs(props).region
    const loading = ref(false)
    const joinOptions = toRefs(props).joinOptions

    const fetchData = (provinsi, region, q = null) => {
      if (provinsi.length <= 0) provinsi = null
      if (region.length <= 0) region = null
      loading.value = true
      apiClient
        .get('/api/filter/area-by-role?active=1', { params: { provinsi, region, q } })
        .then(response => {
          let sorted = response.data
          if (joinOptions.value.length > 0) {
            const merged = merge(keyBy(joinOptions.value, 'id'), keyBy(sorted, 'id'))
            sorted = values(merged)
          }
          data.value = sorted.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData(provinsi.value, region.value)
    })

    watch(provinsi, (after, before) => {
      emit('update:value', [])
      fetchData(after, region.value)
    })

    watch(region, (after, before) => {
      console.log('region changed')
      fetchData(provinsi.value, after)
    })

    const onSearch = debounce(value => {
      loading.value = true
      findText.value = value
      fetchData(provinsi.value, region.value, value)
    }, 300)

    const highlight = value => {
      if (value === undefined) return
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    watch(joinOptions, (after, before) => {
      if (after.length > 0) {
        const merged = merge(keyBy(after, 'id'), keyBy(data.value, 'id'))
        const sorted = values(merged)
        data.value = sorted.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      }
    })

    return { data, valueState: useVModel(props, 'value'), findText, highlight, onSearch, loading }
  },
}
</script>

<style></style>
